


























































import "weui";
import Vue from "vue";
import { RadioGroup, Radio, Icon, Toast } from "vant";

export default Vue.extend({
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Icon.name]: Icon
  },
  data() {
    return {
      planOrderId: this.$route.query.planOrderId || 0,
      batteryIdList: [] as string[],
      newBatteryIdList: [] as string[],
      mark: "",
      isFee: "0",
      fee: 0
    };
  },
  created() {
    const batteryIds = this.$route.query.batteryIds as string;
    const difs = this.$route.query.difs as string;
    this.batteryIdList = batteryIds ? batteryIds.split(",") : [];
    this.newBatteryIdList = Array<string>(this.batteryIdList.length).fill("");

    const difList = difs ? difs.split(",") : [];
    for (let i = 0; i < difList.length; i++) {
      if (i >= this.newBatteryIdList.length) {
        break;
      }
      this.$set(this.newBatteryIdList, i, difList[i]);
    }
  },
  methods: {
    addBattery() {
      this.batteryIdList.push("-1");
      this.newBatteryIdList.push("");
    },
    removeBattery(i: number) {
      this.$set(this.newBatteryIdList, i, -1);
      Toast("此电池将会从该租售订单中移除");
    },
    save() {
      const isFee = parseInt(this.isFee);
      if (this.batteryIdList.length != this.newBatteryIdList.length) {
        Toast.fail("错误");
        return;
      }
      if (!this.mark) {
        Toast.fail("请输入更换理由");
        return;
      }
      if (isFee && !this.fee) {
        Toast.fail("请输入收费金额");
        return;
      }
      if (!isFee) {
        this.fee = 0;
      }
      const batteryIdList = [];
      const newBatteryIdList = [] as string[];
      // 去除不变更项
      for (let i = 0; i < this.newBatteryIdList.length; i++) {
        const id = this.newBatteryIdList[i];
        if (id) {
          batteryIdList.push(this.batteryIdList[i]);
          if (id != "-1" && newBatteryIdList.includes(id)) {
            Toast.fail("新电池输入重复");
            return;
          }
          newBatteryIdList.push(this.newBatteryIdList[i]);
        }
      }

      const data = {
        planOrderId: this.planOrderId,
        oldBatteryIds: batteryIdList.join(","),
        newBatteryIds: newBatteryIdList.join(","),
        mark: this.mark,
        isFee: isFee,
        fee: this.fee
      };
      this.$axios.post("/api/manage/batteryPlanOrderBatterySwitchMulti", data).then(() => {
        Toast.success({
          message: "操作成功",
          onClose: () => {
            this.$router.go(-1);
          }
        });
      });
    }
  }
});
